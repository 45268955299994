import { environment } from 'src/environments/environment';

const ImageFormats = ['jpg', 'png', 'webp', 'jpeg', 'tiff', 'svg'];
const VideoFormats = ['mov', 'wmv', 'mkv', 'mpeg', 'mp4', 'avi', 'ogg', '3gp', 'webm'];

export const isDeviceDesktop = (): boolean => {
   return window.innerWidth > 769;
};

export const isMobile = (): boolean => {
   return window.innerWidth <= 769;
};

export const IsOptimizedImage = (imageURL: string): boolean => {
   return (
      imageURL.includes(environment.IMAGE_COMPRESSION_BASE_URL) ||
      imageURL.includes(environment.IMAGE_COMPRESSION_BASE_URL_NEW)
   );
};

export const getResponsiveImages = (imageURL: string): string => {
   const imageDIR = imageURL.replace(':9500', '');
   if (
      !imageURL.includes(environment.IMAGE_COMPRESSION_BASE_URL) &&
      !imageURL.includes(environment.IMAGE_COMPRESSION_BASE_URL_NEW)
   ) {
      return imageURL;
   } else
      return `${imageDIR}20.jpg 300w,
  ${imageDIR}30.jpg 400w,
  ${imageDIR}40.jpg 600w,
  ${imageDIR}60.jpg 800w,
  ${imageDIR}80.jpg 1200w,`;
};

export const getCbFarmDefaultImage = (imageURL: string): string => {
   return '/assets/images/bg.png';
};

export const getDefaultImage = (imageURL: string): string => {
   const imageDIR = imageURL.replace(':9500', '');
   if (imageURL.includes(environment.IMAGE_COMPRESSION_BASE_URL_NEW)) {
      return imageDIR + 'thumbnail.jpg';
   }
   if (!imageURL.includes(environment.IMAGE_COMPRESSION_BASE_URL)) {
      return '/assets/images/bg.png';
   }
   return imageDIR + '20.jpg';
};

export const getHighQualityImageBasedOnURL = (imageURL: string): string => {
   const imageDIR = imageURL.replace(':9500', '');
   return imageURL.includes(environment.IMAGE_COMPRESSION_BASE_URL) ||
      imageURL.includes(environment.IMAGE_COMPRESSION_BASE_URL_NEW)
      ? imageDIR + '90.jpg'
      : imageURL;
};

export const getMediumQualityImageBasedOnURL = (imageURL: string): string => {
   const imageDIR = imageURL.replace(':9500', '');
   return imageURL.includes(environment.IMAGE_COMPRESSION_BASE_URL) ||
      imageURL.includes(environment.IMAGE_COMPRESSION_BASE_URL_NEW)
      ? imageDIR + '50.jpg'
      : imageURL;
};

export const getLowQualityImageBasedOnURL = (imageURL: string): string => {
   const imageDIR = imageURL.replace(':9500', '');
   return imageURL.includes(environment.IMAGE_COMPRESSION_BASE_URL) ||
      imageURL.includes(environment.IMAGE_COMPRESSION_BASE_URL_NEW)
      ? imageDIR + '20.jpg'
      : imageURL;
};

export const getMinimumQualityImageBasedOnURL = (imageURL: string): string => {
   const imageDIR = imageURL.replace(':9500', '');
   return imageURL.includes(environment.IMAGE_COMPRESSION_BASE_URL) ||
      imageURL.includes(environment.IMAGE_COMPRESSION_BASE_URL_NEW)
      ? imageDIR + '10.jpg'
      : imageURL;
};

export const checkFileFormat = (fileUrl: string): string | undefined => {
   const extention = fileUrl.substring(fileUrl.lastIndexOf('.') + 1);
   return ImageFormats.includes(extention.toLocaleLowerCase())
      ? 'image'
      : VideoFormats.includes(extention.toLocaleLowerCase())
        ? 'video'
        : fileUrl.substring(fileUrl.indexOf(':') + 1, fileUrl.indexOf('/'));
};

export const supportsVideoType = (video: HTMLVideoElement) => {
   const formats = {
      ogg: 'video/ogg; codecs="theora"',
      h264: 'video/mp4; codecs="avc1.42E01E"',
      webm: 'video/webm; codecs="vp8, vorbis"',
      vp9: 'video/webm; codecs="vp9"',
      hls: 'application/x-mpegURL; codecs="avc1.42E01E"'
   };
   let supports = false;
   Object.keys(formats).map(item => {
      if (video.canPlayType(formats[item as keyof typeof formats] || item) === 'probably') {
         supports = true;
      }
   });
   return supports;
};
